<template>
  <v-btn 
    depressed
    :disabled="!enabled" 
    color="turquesa"
    @click="$emit('click')"
    :class="
    [
      'body--text',
      leftFlat ? 'rounded-l-0' : ''
    ]"
    min-width="150px">
    <v-img 
      :class="
      [
        'mr-2', 
        'pl-0'
      ]"
       max-width="12px" src="assets/ic-arrow-blue-right.svg" />
    ENVIAR
    </v-btn>
</template>

<script>
export default {
  name: 'EnviarButton',
  props: {
    leftFlat: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
</style>